@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Urbanist';
  scroll-behavior: smooth;
}

/* react select custom styles */
.select_style .select_control__control {
  border-radius: 8px;
  font-size: 14px;
  background: #fff;
  border: 1px solid #96a397;
  color: #333333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: 48px;
  width: 100%;
}

.select_style .select_control__control:hover {
  border: 1px solid #abb6ac !important;
  box-shadow: none !important;
}

.select_control__menu .select_control__menu-list .select_control__option {
  cursor: pointer;
  font-size: 14px;
  color: #333333;
}

.select_control__indicator-separator {
  display: none !important;
}

.select_control__multi-value {
  background-color: #dbffdf !important;
  border-radius: 4px !important;
}
.select_control__multi-value__label {
  color: #2b8c34 !important;
  font-weight: 500 !important;
}

.select_control__multi-value__remove {
  color: #2b8c34 !important;
}

.select_control__multi-value__remove:hover {
  background-color: #a7eaad !important;
}

/* Badges */
.success-badge {
  background: #dbffdf;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2b8c34;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.pending-badge {
  background: #fff3ce;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #d5a407;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.error-badge {
  background: #fdded4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b92043;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.dispatched-badge {
  background: #C9F7FC;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0066AF;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.done-badge {
  background: #ECEEEC;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #344335;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.paid-badge {
  background: #fff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #96A397;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.pay-badge {
  background: #2B8C34;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: white;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}
 
.ct-toast-success{
  background: #2B8C34;
  color: white;
}

.campaignScroll::-webkit-scrollbar {
  width: 0.5px;
}
.call2orderOverflow::-webkit-scrollbar {
  width: 6px;
}
.call2orderOverflow::-webkit-scrollbar-track {
  background: #f7f7f7;
  border-radius: 8px;
}

/* Handle */
.call2orderOverflow::-webkit-scrollbar-thumb {
  background: #fff;
    border-radius: 8px;
}

/* width */
.sidebar_scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.sidebar_scroll::-webkit-scrollbar-track {
  background: #f7f7f7;
}

/* Handle */
.sidebar_scroll::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
.sidebar_scroll::-webkit-scrollbar-thumb:hover {
  background: #f7f7f7;
}

.farmers_scroll::-webkit-scrollbar {
  width: 1px;
}

input[type="radio"] {
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;

  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;

  /* The background will be the radio dot's color. */
  background: #FFFFFF;

  /* The border will be the spacing between the dot and the outer circle */
  border: 1px solid #96A397;

  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #96A397;
  
}

input[type="radio"]:checked {
  box-shadow: 0 0 0 1px #2B8C34;
  background-color: #2B8C34;
  border: 3px solid #fff;
}